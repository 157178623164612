
const TRACKING_ID = "UA-107296342-2";

exports.onPreRouteUpdate = function() {
    if (window.performance) {
        window.startRouteUpdate = Math.round(performance.now());
        if (process.env.NODE_ENV !== 'production') {
            console.log("Start route update at", window.startRouteUpdate);
        }
    }
};

exports.onRouteUpdate = function() {
    if (window.performance) {
        if (window.startRouteUpdate) {
            const timeSinceStart = Math.round(performance.now()) - window.startRouteUpdate;
            if (process.env.NODE_ENV === 'production' && typeof window.gtag === 'function') {
                // Sends the timing hit to Google Analytics.
                window.gtag('event', 'timing_complete', {
                    'name': 'load',
                    'value': timeSinceStart,
                    'event_category': 'Route change'
                });
            } else {
                console.log("Time for page load", timeSinceStart, "ms");
            }
        } else {
            const timeSincePageLoad = Math.round(performance.now());
            if (process.env.NODE_ENV === 'production' && typeof window.gtag === 'function') {
                // Sends the timing hit to Google Analytics.
                window.gtag('event', 'timing_complete', {
                    'name': 'load',
                    'value': timeSincePageLoad,
                    'event_category': 'JS Load'
                });
            } else {
                console.log("Time for js deps load", timeSincePageLoad, "ms");
            }
        }
    }
    if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
        fbq('track', 'ViewContent');
    }
    if (process.env.NODE_ENV === `production` && typeof window.gtag === 'function') {
        let locationStr = '';
        if (location) {
            locationStr = `${location.pathname}${location.search}${location.hash}`;
        }
        window.gtag('config', TRACKING_ID, {
            page_path: locationStr,
            anonymize_ip: true,
        });
    }
};
